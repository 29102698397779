<template>
  <div id="app">
    <div class="topBar">
      <div>
        <img class="logo" alt="logo" src="./assets/logo.png">
      </div>
      <div class="profile-box">
        <div class="profile-imgBox"></div>
      </div>

    </div>
    <div class="d-flex h-100">
      <div class="feature-box">
        <carousel :items="4" :autoHeight="true" :margin="24" :nav="false" :dots="false" :autoWidth="true">
          <div class="my-box">
            <h4 class="">Prompt Helper</h4>
            <p>Enhance paper generation precision with keyword assistance.</p>
          </div>
          <div class="my-box">
            <h4 class="">Generate Exam paper</h4>
            <p>It generates exams tailored to student standards with just one click.</p>
          </div>
          <div class="my-box">
            <h4 class="">Review Exam Questions</h4>
            <p>an essential feature for boosting critical thinking and reinforcing key concepts in exam preparation</p>
          </div>
          <div class="my-box">
            <h4 class="">Analysis</h4>
            <p>It will generate a report displaying the average score and the standard deviation of the students.</p>
          </div>

        </carousel>

        <div class="chat-box">
          <div class="chat-display">
            <div class="d-flex align-items-center justify-content-center">
              <img class="logo" alt="logo" src="./assets/logo-icon.png">
              <div class="ms-3">
                <img class="logo" alt="logo" src="./assets/logo.png">

              </div>

            </div>

          </div>
          <div v-for="(item, index) in chatBox" :key="index">
            <div v-if="(Object.entries(item).length !== 0)">
              <div class="d-flex align-items-center justify-content-end" style="margin-bottom: 24px;">
                <p class="text-right mb-0">{{ item['user'] }}</p>
                <div class="user-circle">HT</div>

              </div>
              <p class="text-left">{{ item['ai'] }}</p>

            </div>
          </div>

          <div v-if="isLoading" class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>


          <b-form class="input-box" @submit.prevent="submitInput">
            <b-input-group>
              <b-form-input v-model="input"></b-form-input>

              <b-input-group-append>
                <b-button class="submit-btn" type="submit"><img class="logo" alt="logo"
                    src="./assets/images/CaretCircleRight.png"></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>

        </div>

      </div>
      <div class="record-box">
        <div class="my-box mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="">Average Score</h4>
            <p class="view-more">View More</p>

          </div>
          <hr>
          <div class="d-flex align-items-center">
            <div class="circle-box">

            </div>
            <h4 class="mx-3 flex-grow-1">Statistic</h4>

            <img class="" alt="graph" src="./assets/images/graph.png">

          </div>
        </div>

        <div class="my-box mb-4">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="">Resources Overview</h4>
            <p class="view-more">View More</p>

          </div>
          <hr>
          <div class="d-flex align-items-center file-box">
            <div class="circle-box">

            </div>
            <div class="mx-3 flex-grow-1">
              <p class="mb-0">English for Preschool.PDF</p>
              <p class="date mb-0">12/08/2023</p>
            </div>
            <div class="img-box">
              <img class="" alt="download" src="./assets/images/download.png">

            </div>

          </div>
          <div class="d-flex align-items-center file-box">
            <div class="circle-box">

            </div>
            <div class="mx-3 flex-grow-1">
              <p class="mb-0">English for Preschool.PDF</p>
              <p class="date mb-0">12/08/2023</p>
            </div>
            <div class="img-box">
              <img class="" alt="download" src="./assets/images/download.png">

            </div>

          </div>
          <div class="d-flex align-items-center file-box">
            <div class="circle-box">

            </div>
            <div class="mx-3 flex-grow-1">
              <p class="mb-0">English for Preschool.PDF</p>
              <p class="date mb-0">12/08/2023</p>
            </div>
            <div class="img-box">
              <img class="" alt="download" src="./assets/images/download.png">

            </div>

          </div>
          <div class="d-flex align-items-center file-box mb-0">
            <div class="circle-box">

            </div>
            <div class="mx-3 flex-grow-1">
              <p class="mb-0">English for Preschool.PDF</p>
              <p class="date mb-0">12/08/2023</p>
            </div>
            <div class="img-box">
              <img class="" alt="download" src="./assets/images/download.png">

            </div>

          </div>
        </div>


        <div class="my-box special">
          <div class="d-flex align-items-center justify-content-between">
            <h4 class="">Average Score</h4>
            <p class="view-more">View More</p>

          </div>
          <hr>
          <div class="d-flex align-items-center">
            <div class="circle-box">

            </div>
            <h4 class="mx-3 flex-grow-1">Statistic</h4>

            <img class="" alt="graph" src="./assets/images/graph.png">

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import { submitForm } from './request/api/api'

export default {
  name: 'App',
  components: {
    carousel
  },
  data() {
    return {
      input: '',
      startChat: false,
      chatBox: [{
        // user: 'xxx',
        // ai: 'xxx'
      }],
      isLoading: false,
      chatGroup: {},
    }

  },
  methods: {
    submitInput() {
      var self = this;
      this.startChat = true;

      let formData = new FormData();
      formData.append('prompt', encodeURIComponent(this.input));

      this.chatBox[this.chatBox.length - 1]['user'] = this.input;
      this.input = '';

      self.isLoading = true;
      var result;
      result = submitForm(formData);
      result.then(function (value) {
        console.log(value);
        self.chatBox[self.chatBox.length - 1]['ai'] = value['data']['ai_response'];

        self.chatBox.push({});
        console.log(self.chatBox);
        self.isLoading = false;

      }).catch(function (error) {
        self.isLoading = false;
        console.log(error);
      });
    },
  }
}
</script>

<style></style>
