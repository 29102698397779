import request from '../request';
import config from '../config';

var current = config.url;
// var current = location.origin + '/';

function retHeader() {
	const header = {
		'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
		'Authorization': 'Bearer' + localStorage.getItem("aibotToken"),
	};
	return header;

}

export function submitForm(postData) {
	const data = request({
		url: current + 'Home/Index/submit',
		method: 'post',
		data: postData,
		headers: retHeader()
	});

	return Promise.resolve(data);
}

export function get(page) {
	const data = request({
		url: current + '' + page,
		method: 'get',
		headers: retHeader()
	});

	return Promise.resolve(data);
}

